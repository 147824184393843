.about{
  border-top: solid 1px #E1E7EC;
  padding-left: 10px;
  padding-right: 10px;

  .col-container{
    max-width: 1250px;
    width: calc(100% - 80px);
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    .col-2-3{
      width: calc(66.66% - 102px);
      border-left: none;
    }
  }

  .title{
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    line-height: 56px;
    margin-top: 100px;
    margin-bottom: 100px;
    color: #686EFC;
  }

  .content{
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 40px;
    color: #525C68;
  }

  img{
    width:100%;
    margin-left: auto;
    margin-right: auto;
  }

  .ecosystem{
    text-align: center;
    margin-top: 100px;
  }

  .withSeparator{
    border-top: solid 2px #E1E7EC;
    margin-top: 85px;
    padding-top: 80px;

    h2{
      max-width: 720px;
      margin-left: auto;
      margin-right: auto;
    }
    p{
      max-width: 720px;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
    }
    a{
      font-size: 28px;
    }
  }

  .withoutSeparator{
    h2{
      max-width: 720px;
      margin-left: auto;
      margin-right: auto;
    }
    p{
      max-width: 720px;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
    }
    a{
      font-size: 28px;
    }
  }

  .subTitle{
    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 40px;
    margin-top: 100px;
    margin-bottom: 50px;
    text-align: center;
    color: #393294;
  }

  .baseline {
    text-align: center;
  }

  .who {
    background-color: #FFFEEE;
    margin-top: 50px;
    padding-top: 94px;
    padding-bottom: 224px;

    .half{
      width: calc(50% - 40px);
      padding: 0;
      border-left: none;
    }

    .whoContent {
      margin-left: auto;
      margin-right: auto;
      text-align: left;
      max-width: 1250px;
      width: calc(100% - 80px);
      padding-left: 40px;
      padding-right: 40px;
      .subTitle{
        text-align: left;
      }
      p{
        max-width: 720px;
      }
      .card{
        background: #FFFFFF;
        box-shadow: 0px 3px 12px rgba(21, 40, 77, 0.13);
        border-radius: 20px;
        padding:44px 52px;
        img{
          width:72px;
          height: 72px;
        }
        .cardTitle{
          font-family: Noto Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 32px;
          color: #212934;
        }
        .cardContent{
          font-family: Noto Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 28px;
          color: #525C68;
          width: calc(100% - 80px);
        }
        a{
          float: right;
          width: 64px;
          height: 64px;
          background: #FFFFFF;
          border: 2px solid #E1E7EC;
          box-sizing: border-box;
          border-radius: 64px;
          align-items: center;
          justify-content: center;
          display: flex;
          color: #212934;
          font-size: 24px;
          margin-top: -64px;
        }
      }
    }
  }

}

.customLabel {
    position: absolute;
    right: 5%;
    top: 0;
    margin-top: 5px;
    line-height: 35px;
    text-align: right;
    -webkit-transform: translateX(70px);
    -moz-transform: translateX(70px);
    -o-transform: translateX(70px);
    -ms-transform: translateX(70px);
    transform: translateX(70px);
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
}

.row {
  position:relative;
}

.row:hover .customLabel {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -o-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

label {
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  color: $darker-blue;
  margin-top: 10px;
}

label.dark {
  color: $disabled;
}

input[type="password"],
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="date"] {
  height: 40px;
  border: 2px solid #E2E4E7;
  border-radius: 10px;
  width: calc(100% - 32px);
  padding: 0;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
  margin-top: 5px;
  font-size: 14px;
  background-color: $white;
}

input[type="text"].search {
  background-color: #FAFAFA;
  width: 85%;
  margin-top: 0;
}

textarea {
  border: 2px solid #E2E4E7;
  border-radius: 10px;
  width: calc(100% - 30px) !important;
  padding: 15px;
  margin-bottom: 15px;
  margin-top: 5px;
  font-size: 14px;
  display: block;
  font-family: 'Noto Sans', sans-serif;
}

select {
  display: block;
  width: 100%;
  text-align: center;
  height: 116px;
}

.basic-single {
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  z-index: 1;
  text-align: left !important;
}

.basic-single input{
  margin:0;
  height: auto;
  line-height: 18px;
}

.basic-single .select__value-container {
  height:40px;
}

.select__control{
  border: 2px solid #E2E4E7 !important;
  border-radius: 10px !important;
  font-family: 'Noto Sans', sans-serif;
}

textarea {
  width: 100%;
  height: 100px;
}

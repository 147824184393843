.list{
  margin-top: 30px;

  .list-item{
    color: $dark-blue;
    display: block;
    background-color: $white;
    box-shadow: $shadow-grey;
    border-radius: 11px;
    position: relative;
    text-align: left;
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    margin-top: 12px;
    padding-right: 100px;

    a {
      text-decoration: none;
      display: block;
    }

    p {
      display: inline-block;
      margin: 0;
    }

    .list-title{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: calc(100% - 200px)
    }

    .list-subtitle{
      vertical-align: top;
      color: #A3B9CE;
      line-height: 38px;
      margin-top: 6px;
      margin-right: 10px;
      width: 70px;
      border-right: solid 1px #DDE5ED;
    }

    .equipment{
      float:right;
      display: inline-block;
      margin-right: 10px
    }

    .unavailable{
      display: inline-block;
      width: 30px;
      height: 30px;
      background-color: #EC677E;
      border: solid 4px #A42339;
      border-radius: 30px;
      margin-top: 6px;
      margin-right: 10px;
      vertical-align: top;
      float:right;
    }

    .available{
      display: inline-block;
      width: 30px;
      height: 30px;
      background-color: #E9EEF4;
      border: solid 4px #DFE6EC;
      border-radius: 30px;
      margin-top: 6px;
      margin-right: 10px;
      vertical-align: top;
      float:right;
    }

    .tools {
      right: 0px;
      top: 0px;
      position: absolute;
      border-left: solid 1px #DDE5ED;
      line-height: 38px;
      margin-top: 6px;
      padding-left: 10px;
      padding-right: 10px;
      a {
        display: inline-block;
        color: #A3B9CE;
        font-size: 16px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

}

@media screen and (max-width: 790px) {

  .list{
    margin-left: 20px;
    margin-right: 20px;
    .list-item{
      padding-right: 10px;
      .list-title{
        width: calc(100% - 130px);
      }
    }
  }

}

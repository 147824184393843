footer {
  border-top: solid 2px #E1E7EC;
  text-align: left;
  background-color: $white;
  padding: 20px;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  display: block;
  height: 70px;
  
  nav{
    display: inline-block;
  }

  .navbar {
    padding-left: 10px;
    padding-right: 20px;
    margin-top: 0;
    float:right;

    li {
      display: inline-block;
      list-style: none;
      margin-top: 20px;
      margin-left: 10px;
      margin-right: 10px;

      a {
        color: $dark-grey;
        font-size: 15px;
        text-decoration: none;
        display: inline-block;
        position: relative;
      }

      a:hover {
        opacity: 1;
        color: $tonic-blue;
      }

    }

    li.active a {
      svg{
        color: $tonic-blue
      }
    }

  }

}

footer p{
  margin: 0;
  margin-top: 26px;
}

footer .copyright svg {
  color: $tonic-blue;
}
@media screen and (max-width: 1280px) {
  .chart-container.half {
    width: calc(100% - 78px);
  }
  .col.half{
    width:100%;
    margin-left: 0;
    margin-right: 0;
    border-left: none;
    padding-left: 0;
    padding-right: 0;
  }
}

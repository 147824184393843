.btn {
  display: inline-block;
  cursor: pointer;
  line-height: 48px;
  font-size: 14px;
  text-decoration: none;
  height: 48px;
  background-color: $tonic-blue;
  color: $white;
  border: none;
  border-radius: 8px;
  width: auto;
  margin-bottom: 0px;
  padding-top: 0;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: $shadow-tonic;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  svg {
    margin-right: 10px;
    color: $white;
  }

  .initial {
    font-weight: bold;
    color: $white;
    margin-right: 10px;
  }

}

.btn.inactive {
  background-color: $disabled;
  color: $hover-text;
  svg {
    margin-right: 10px;
    color: $tonic-blue;
  }

  .initial {
    font-weight: bold;
    color: $tonic-blue;
  }
}

.btn:hover {
  opacity:0.8;
  color: $white;
}

.btn.inactive:hover {
  opacity:0.8;
  color: $tonic-blue;
}

.btn.secondary {
  border:solid 1px $tonic-blue;
  background-color: $white;
  color: $dark-blue
}

.btn.secondary:hover {
  background-color: #f7f7f7;
  color: $dark-blue
}

.col-container {
  display:block;
  width:100%;
}

.col {
  display:inline-block;
  border-left: solid 1px #F4F4F6;
  padding-left: 40px;
  padding-right: 40px;
  vertical-align: top;
}

.col:first-child {
  padding-left: 0;
  padding-right: 40px;
  border-left: none;
}

.col:last-child {
  padding-left: 40px;
  padding-right: 0;
}

.col.half {
  width: calc(50% - 42px);
}

/*
COLONNES
*/

.col-1-4{
  display: inline-block;
  vertical-align: top;
  width:calc(25% - 10px);
  margin-right: 10px;
}

.col-1-3{
  display: inline-block;
  vertical-align: top;
  width:calc(33.33% - 50px);
  margin-right: 10px;
}

.col-2-3{
  display: inline-block;
  vertical-align: top;
  width:calc(66.66% - 52px);
  margin-left: 10px;
}


.tabs{
  border-bottom: solid 2px #e9eaeb;
  text-align: left;
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;

  .tab{
    display:inline-block;
    font-size: 18px;
    height: 56px;
    line-height: 56px;
    border-radius:11px;
    color:#A3B9CE;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 10px;
    svg{
      margin-right:10px;
    }
  }

  .tab.active {
    background-color: #EAEEF7;
    color: $tonic-blue;
  }
}

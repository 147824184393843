.tiles{
  margin-top: 30px;

  .tile{
    width: 125px;
    padding: 10px;
    margin: 10px;
    display: inline-block;
    position: relative;
    text-align: left;
    box-shadow: $shadow-grey;
    border-radius: 11px;
    background: #fff;
    z-index: 1;
    border-bottom: solid 10px;

    a {
    }

    p {
      font-weight: bold;
      margin: 0;
    }

    .tile-number{
      font-size:28px;
      margin-top:10px
    }

    .tile-total{
      color:#828282;
      font-weight: normal;
    }

    .tool {
      display: none;
      float:right;
      width: 15px;
      text-align: center;
    }

    .tools {
      display: none;
      box-shadow: $shadow-grey;
      border-radius: 11px;
      background: #fff;
      padding: 10px;
      position: absolute;
      top: 0;
      margin-left: 130px;
      a{
        display: block;
        color: #000;
        margin-right: 30px;
        margin-left: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }

  }

  .tile:hover{
    z-index: 2;
    box-shadow: 0px 27px 23px rgba(47, 51, 58, 0.4);

    .tool {
      display: block;
    }

    .tools {
      display: block;
    }
  }

}

@media screen and (max-width: 790px) {
  .tiles {
    margin-left: 10px;
    margin-right: 10px;

    .tile {
      width: calc(50% - 40px);
    }
  }
}

.page-login{
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $main-background;

  .col-login{
    border-radius: 10px;
    background-color: $white;
    box-shadow: $shadow-dark;
    min-width: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }

  label{
    color:$dark-grey;
  }

  .header-login{
    text-align: center;
  }

  .form-login h1 {
    font-size: 20px;
    position: relative;
    color:$dark-grey;
    opacity:1;
  }

  .form-login{
    margin-top: 30px;
    width: calc(100% - 80px);
    opacity:1;
    text-align: center;
  }
}

@media screen and (max-width: 790px) {
  .page-login{
    padding-top: 20px !important;

    .col-login{
      width:100%;
      min-width: initial !important;
    }
    .form-login{
      width:calc(100% - 40px);
      margin-left: auto;
      margin-right: auto;
    }
  }
}

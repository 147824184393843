
/*RESPONSIVE*/

.showOnMobile{
  display:none;
}

@media screen and (max-width: 1023px) {
  .hideOnTablet {
    display: none !important;
  }
}

@media screen and (max-width: 790px) {
  .hideOnMobile {
    display: none !important;
  }
  .showOnMobile {
    display: block !important;
  }
  .col-1-3 {
    width:100%;
    margin-right: 0;
    position: relative !important;
  }
  .col-2-3 {
    width:100% !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
    position: relative !important;
  }
  .col-1-4 {
    width:100%;
    margin-left: 0;
    position: relative !important;
  }
  .col.half {
    width:100% !important;
    margin-left: 0;
    padding-left: 0;
    margin-top: 20px;
  }
  .container {
    margin-left: 0 !important;
  }
  .marginOnMobile {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .line .lineContent {
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    width: calc(100% - 54px);
    display: inline-block;
  }
  .container-add {
    margin-left: 30px !important;
    width: calc(100% - 60px) !important;
  }
  .tabs{
    text-align: center;
    margin-left: 0
  }
  .tab{
    padding-left: 10px !important;
    padding-right: 10px !important
  }
  .page-title{
    max-width: 95% !important;
  }
  .btn-question{
    width: auto !important;
  }
  .questions{
    display: block !important;
    grid-gap: inherit !important;
    grid-template-columns: inherit !important;
    grid-auto-rows: inherit !important;
    .question{
      width:calc(100% - 80px) !important;
      margin-top: 20px !important;
    }
  }
}

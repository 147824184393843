header {
  display: flex;
  flex-direction: column;
  z-index: 3;

  nav{
    display: inline-block;
  }

  .navbar-brand{

    position:absolute;
    margin-left: 40px;
    margin-top: 30px;

    img{
      height:24px;
      display:inline-block;
    }
    p{
      line-height: 40px;
      margin:0;
      color:#fff;
      vertical-align: top;
      margin-left: 10px;
      display:inline-block;
    }
  }

  .navbar {
    padding-left: 10px;
    padding-right: 20px;
    margin-top: 0;
    float:right;
    line-height: 48px;

    li {
      display: inline-block;
      list-style: none;
      margin-top: 20px;
      margin-left: 10px;
      margin-right: 10px;

      svg {
        width: 58px;
        color:#A3B9CE;
      }

      a {
        color: $dark-grey;
        font-size: 15px;
        text-decoration: none;
        display: inline-block;
        position: relative;
      }

      a:hover {
        opacity: 1;
        color: $tonic-blue;
      }

      .circled {
        border: solid 2px #e9eaeb;
        height: 48px;
        line-height: 48px;
        border-radius: 48px;
        display: inline-block;
        padding: 0 30px 0 30px;
        font-weight: bold;
        background: transparent;
      }

      .language {
        height: 48px;
        line-height: 48px;
        border-radius: 48px;
        display: inline-block;
        padding: 0 20px 0 14px;
        font-weight: bold;
        background: #686EFC;
        color: #ffffff;
        svg{
          color: #fff;
          margin-right: 10px;
          font-size: 24px;
          vertical-align: top;
          margin-top: 12px;
        }
        .caret{
          font-size: 18px;
          margin-top: 16px;
          margin-right: 0;
          margin-left: 20px;
        }
      }

    }

    li.active a {
      svg{
        color: $tonic-blue
      }
    }

  }

  .alertBtn{
    background-color: #EC677E;
    width:45px;
    height:45px;
    line-height: 45px;
    text-align: center;
    border-radius: 45px;
    box-shadow: $shadow-dark;
    position: fixed;
    top: 20px;
    right: 20px;
    a{
      color: #fff;
      font-size: 24px;
    }

  }
}

header.yellow {
  background-color: $main-background;
}

.header-languages {
  position: absolute;
  right: 30px;
  border: 1px solid #E1E7EC;
  box-shadow: 0px 8px 12px rgba(21, 40, 77, 0.13);
  border-radius: 10px;
  padding: 20px;
  min-width: 200px;
  background-color: $white;
  z-index:2;
}

.header-submenu {
  position: absolute;
  right: 30px;
  border: 1px solid #E1E7EC;
  box-shadow: 0px 8px 12px rgba(21, 40, 77, 0.13);
  border-radius: 10px;
  min-width: 200px;
  background-color: $white;
  z-index:2;

  .submenu {
    padding: 10px 20px;
  }

}

@media screen and (max-width: 790px) {
  .navbar-brand {
    position: initial !important;
    display: block;
    margin-top: 0;
    margin-left: 0;
  }
}

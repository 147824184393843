.menu{
  margin-top: 30px;
  display: inline-block;
  vertical-align: top;

  .list-item{
    color: $dark-blue;
    display: block;
    position: relative;
    text-align: left;
    padding-left: 20px;
    margin-top: 12px;
    padding-right: 100px;

    a {
      text-decoration: none;
      display: block;
    }

    p {
      display: inline-block;
      margin-top: 8px;
      margin-bottom: 8px;
      font-size: 18px;
    }

    .tools {
      right: 0px;
      top: 0px;
      position: absolute;
      border-left: solid 1px #DDE5ED;
      line-height: 38px;
      margin-top: 6px;
      padding-left: 10px;
      padding-right: 10px;
      a {
        display: inline-block;
        color: #A3B9CE;
        font-size: 16px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

}

@media screen and (max-width: 790px) {

  .menu{
    margin-left: 20px;
    margin-right: 20px;
    .list-item{
      padding-right: 10px;
      .list-title{
        width: calc(100% - 130px);
      }
    }
  }

}

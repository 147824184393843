// Google Fonts
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,600,300,700,400italic');

$font-stack: 'Noto Sans', sans-serif;
$main-background: #FFF6E8;
$disabled: #d6d7dd;
$dark-blue: #FFF6E8;
$darker-blue: #342569;
$tonic-blue: #686EFC;
$white: #ffffff;
$dark-grey: #2F3955;
$hover-text: #6B6E77;
$error-color: #c92b2b;
$warn-color: #FF8F4C;
$confirm-color: #80c92b;
$shadow-dark: 0px 4px 4px rgba(0, 0, 0, 0.08);
$shadow-tonic: 0px 5px 9px rgba(36, 117, 130, 0.2);
$shadow-grey: 0px 10px 30px rgba(47, 51, 58, 0.2);

$shadow-neumorphism-dark: 4px 4px 7px #122155, -4px -4px 7px #182d73;
$shadow-neumorphism-light: 3px 3px 6px #d9d9d9, -3px -3px 6px #ffffff;
$background-neumorphism-hover: linear-gradient(145deg, #162a6b, #13235a);
$background-neumorphism-hover-light: linear-gradient(145deg, #ffffff, #e6e6e6);;

@import "partials/modal";
@import "partials/col";
@import "partials/forms";
@import "partials/languages";
@import "partials/responsive";
@import "partials/tab";

/*
COMPONENTS
*/

@import "../../components/partials/List/list";
@import "../../components/partials/Menu/list";
@import "../../components/partials/Message/message";
@import "../../components/partials/Button/button";
@import "../../components/partials/Tile/tile";
@import "../../components/partials/header";
@import "../../components/partials/footer";
@import "../../components/auth/login";
@import "../../components/pages/about";

/*
GENERAL
*/

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  background: $white;
}

.mainContainer {
  /*background-color: $main-background;*/
}

h1 {
  font-family: 'Noto Sans', sans-serif;
  font-size: 42px;
  color: $dark-grey;
}

h2 {
  font-family: 'Noto Sans', sans-serif;
  font-size: 30px;
  color: $hover-text;
  font-weight: normal;
}

h3 {
  font-family: 'Noto Sans', sans-serif;
  font-size: 21px;
  font-weight: 400;
  color: $dark-grey;
}

p {
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  color: $dark-grey;
}

/*
LINKS
*/

a{
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  color: $tonic-blue;
  opacity:1;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  cursor: pointer;
}

a:hover {
  opacity:0.8;
}

.btn {
  display: inline-block;
  cursor: pointer;
  line-height: 48px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  height: 48px;
  background-color: $tonic-blue;
  color: $white;
  border: none;
  border-radius: 40px;
  width: 100%;
  margin-bottom: 0px;
  padding-top: 0;
  padding-left: 30px;
  padding-right: 30px;
  /*text-transform: uppercase;*/
  box-shadow:none;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.btn svg {
  margin-right: 10px;
}

.btn .disabled {
  background-color: $disabled;
  cursor:not-allowed;
}

.btn-submit {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.btn-secondary {
  border: solid 2px #e9eaeb;
  background-color: $white;
  color: #333333;
}

.btn:hover {
  opacity:0.8;
  color: $white;
}

.btn-secondary:hover {
  background-color: #f7f7f7;
  color: $tonic-blue
}

.btn-question{
  font-family: 'Noto Sans', sans-serif;
  border: 2px solid #E2E4E7;
  width: 720px;
  border-radius: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding-left: 24px;
  color: #98999E;
  line-height: 72px;
  font-size: 20px;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  cursor: pointer;
}

.btn-mic{
  width: 48px;
  height: 48px;
  background: rgba(224,231,253,1);
  color: $dark-grey;
  display: inline-block;
  line-height: 48px;
  text-align: center;
  border-radius: 48px;
  float: right;
  margin-right: 12px;
  margin-top: 12px;
  font-size: 24px;
}

.btn-question:hover {
  border-color: $tonic-blue;
  box-shadow: 0px 0px 0px 8px rgba(224,231,253,1);
}

/*
MESSAGES
*/

@keyframes displayMessage {
  0%   {bottom: 0; opacity:0}
  100% {bottom: 20px; opacity:1}
}

.message {
  font-family: 'Noto Sans',sans-serif;
  font-size: 14px;
  color: #fff;
  background-color: $confirm-color;
  margin-bottom: 20px;
  padding: 10px 30px;
  border-radius: 5px;
  text-align: center;
  position: fixed;
  bottom: 0;
  opacity: 1;
  left: 50%;
  margin-left: -230px;
  width: 400px;
  animation-name: displayMessage;
  animation-duration: .5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  a {
    color: #ffffff;
  }
}

.error {
  text-align: center;
  color: $error-color;
  font-family: 'Noto Sans',sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.confirm {
  text-align: center;
  color: $confirm-color;
  font-family: 'Noto Sans',sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.message.error {
  color: #fff;
  background-color: $error-color;
}

.message.confirm {
  color: #fff;
  background-color: $confirm-color;
}

.tooltip {
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  line-height: normal;
  color: $dark-grey;
}

/*
CONTAINER
*/

.page-container {
  padding-top: 100px;
  padding-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;

  .container {
    background-color: #fff;
    border-radius: 20px;
    padding:20px;
    margin-top: 30px;
  }

  .questions {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fill,minmax(40%,1fr));
    grid-auto-rows: 0;
  }

  .question {
    padding:40px;
    display: inline-block;
    background-color: #EBFAFF;
    vertical-align: top;
    margin-top:0;

    .btn-speak{
      background: #2F3955;
      width: 48px;
      height: 48px;
      float: right;
      line-height: 48px;
      text-align: center;
      border-radius: 48px;
      color: #fff;
      font-size: 24px;
      margin-top: -20px;
      margin-right: -20px;
    }

  }

  .question-wrapper {
    overflow: hidden;
    position: relative;
  }

  .question-check {
    width:80px;
    height:80px;
    display:block;
    background: #61BF88;
    color:#fff;
    border-radius:80px;
    margin-left: auto;
    margin-right: auto;
    font-size: 40px;
    line-height: 80px;
  }

  .dashboard-question-header{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    color: #2F3955;
    margin-top: 30px;
    text-decoration: none;
    color: #A7B3BD;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    .col-question{
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }

  .dashboard-question{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    color: #2F3955;
    border-top: solid 1px #E1E7EC;
    border-radius: 0;
    margin-top: 0;
    text-decoration: none;

    .col-question{
      display: flex;
      flex-direction: column;
      .question-status{
        border-radius: 40px;
        height: 40px;
        line-height: 40px;
        padding-left: 15px;
        padding-right: 15px;
        background-color:$tonic-blue;
        color:$white;
        text-align: center;
        font-size: 14px;
      }
      .question-status.disabled{
        background-color:#ECEFF2;
        color:#525C68;
      }
      .question-status.writing{
        background-color:rgba(205, 125, 6, 0.2);
        color:#CD7D06;
      }
    }
  }


  .dashboard-question:hover{
    border-radius: 20px;
    background-color: #ECEFF2
  }
}

@media screen and (max-width: 790px) {
  .page-container {
    margin-top: 0;
    max-width: calc(100% - 60px);
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.data-container {
  position: absolute;
  left: 120px;
  right: 280px;
  top: 90px;
  bottom: 80px;
  overflow-y: auto;
}

.page-title {
  display: inline-block;
  font-size: 52px;
  line-height: 52px;
  position: relative;
  margin-top: 4px;
  margin-bottom: 0;
  vertical-align: top;
  font-weight: bold;
  max-width: calc(100% - 120px);
}

.page-title .title-number{
  font-weight: bold;
}

.page-back {
  color: $white;
  display: inline-block;
  margin: 0;
  vertical-align: top;
  margin-left: 10px;
  margin-top: 16px;
}

.page-title-mobile{
  width: 100%;
  max-width: none;
  background-color: #4E31B5;
  color: #fff;
  text-align: center;
  margin-top: 0;
  padding-top: 29px;
  padding-bottom: 18px;
  box-shadow: $shadow-dark;
  border-radius: 0 0 11px 11px;
  h1{
    font-weight: normal;
    text-transform: uppercase;
    font-size: 15px;
    color: #fff;
  }
}

.page-title-form,
.page-title-link {
  display: block;
  text-align: center;
  margin-bottom: 20px;
}

.page-subtitle {
  display: block;
  margin-top: 20px;
  margin-left: 10px;
}

.page-title-form {
  margin-bottom: 0;
}

.page-container-header{
  position:fixed
}

/*
Empty
*/

.empty{
  text-align: center;
  .empty-illu{
    width:200px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
}

/*
add btn
*/

.add-btn {
  padding-left: 0;
  padding-right: 0;
  width:48px;
  text-align:center;
  line-height: 60px;
}

.add-btn svg {
  width: 14px !important;
  height: 14px;
  margin-right: 0;
  border: solid 2px #fff;
  padding: 5px;
  border-radius: 100%;
}

/*
Popup
*/

.popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .popup-back {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background: $dark-blue;
    animation-name: opacityIn;
    animation-duration: .5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
  .popup-content {
    z-index: 2;
    border-radius: 11px;
    background: $white;
    padding: 20px;
    min-width: 250px;
    text-align: center;
    box-shadow: $shadow-grey;
  }
  .close{
    float:right
  }
  label{
    color:#A3B9CE;
    display: block;
    text-align: left;
  }
  input{
    background: transparent;
    border-radius: 0;
    border-bottom: solid 1px #A3B9CE;
  }
}

@keyframes opacityIn {
  0%   {opacity: 0;}
  100% {opacity: 0.7;}
}
